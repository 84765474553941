<template>
  <div class="meeting-app-info-block">
    <div class="meeting-app-info-header row">
      <h4>{{ question.label }}</h4>
    </div>
    <div class="meeting-app-info-body">
      <div v-if="question.type == 'LongText'">
        <textarea
          class="form-control"
          :name="question.id"
          :id="'question' + question.id"
          v-model="question.userResponse"
        >
        </textarea>
      </div>

      <div v-if="question.type == 'Likert'">
        <div v-for="(likert, index) in question.answers" :key="index">
          <input
            type="radio"
            :name="'question' + question.id + '-' + index"
            :id="'question' + question.id + '-' + index"
            :value="likert"
            v-model="question.userResponse"
            class="mr-2"
          />
          <label :for="'question' + question.id + '-' + index">{{
            likert
          }}</label>
        </div>
      </div>

      <div v-if="question.type == 'Radio'">
        <div v-for="(radioans, index) in question.answers" :key="index">
          <input
            type="radio"
            :name="'question' + question.id + '-' + index"
            :id="'question' + question.id + '-' + index"
            :value="radioans"
            v-model="question.userResponse"
            class="mr-2"
          />
          <label :for="'question' + question.id + '-' + index">{{
            radioans
          }}</label>
        </div>
        <input
          type="radio"
          :name="'question' + question.id + '- Other'"
          :id="'question' + question.id + '- Other'"
          value="Other"
          v-model="question.userResponse"
          class="mr-2"
        />
        <label :for="'question' + question.id + '- Other'"> Other </label>

        <input
          type="text"
          v-model="question.otherResponse"
          v-if="question.userResponse == 'Other'"
          class="form-control"
        />
      </div>

      <div v-if="question.type == 'Checkbox'">
        <div v-for="(check, index) in question.answers" :key="index">
          <input
            type="checkbox"
            :name="'question' + question.id + '-' + index"
            :id="'question' + question.id + '-' + index"
            :value="check"
            v-model="question.userResponse"
            :true-value="[]"
            class="mr-2"
          />
          <label :for="'question' + question.id + '-' + index">{{
            check
          }}</label>
        </div>
        <input
          type="checkbox"
          :name="'question' + question.id + '- Other'"
          :id="'question' + question.id + '- Other'"
          value="Other"
          v-model="question.userResponse"
          :true-value="[]"
          class="mr-2"
        />
        <label :for="'question' + question.id + '- Other'">Other</label>
        <input
          type="text"
          v-model="question.otherResponse"
          v-if="
            question.userResponse && question.userResponse.includes('Other')
          "
          class="form-control"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["question"],
  components: {},
  methods: {},
  computed: {},
  watch: {
    // If the response is updated, update the "UserResponded" property
    "question.UserResponse": function () {
      this.question.UserResponded =
        this.question.UserResponse != null && this.question.UserResponse != "";
    },
  },
};
</script>

<style scoped></style>
